import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/services/api/api.service';
import { EncryptService } from 'src/app/core/services/encrypt-decrypt/encrypt.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { IUploadConfig } from 'src/app/core/utils/interface/shared.interface';
import { UploadComponent } from '../../shared/upload/upload.component';

@Component({
  selector: 'app-functional-testcase',
  templateUrl: './functional-testcase.component.html',
  styleUrls: ['./functional-testcase.component.scss']
})
export class FunctionalTestcaseComponent implements OnInit {
  extractedData: string = "";
  currentText: string;
  intervalId: any;
  index: number = 0;
  texts: string[] = [];
  isLoading: boolean = false;
  testCases: any[] = [];
  testScenarios: any[] = [];
  flatTestCases: any[] = [];

  industry: any;
  includeTestFiles: boolean = false;
  selectedFile: any;
  fileNamesDisplay: string[] = [];
  isTable: boolean = false;
  requirementDescription: string = "";
  verificationCriteria: string = "";
  displayedColumns: string[] = ['testCaseName', 'objective', 'precondition', 'actions', 'postcondition', 'passFailCriteria'];
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    private toastr: ToastrService,
    private uService: UserService,
    private cdr: ChangeDetectorRef,
    private encryptionService: EncryptService
  ) {}

  ngOnInit(): void {
    this.uService.industry$.subscribe(data => {
      this.industry = data;
    });
  }

  onGettext(formdata) {
    this.texts = ["File transfer underway", "Extracting data", "Please wait"];
    this.isLoading = true;
    this.index = 0;
    this.startTextAnimation();
    this.apiService.apiRequestPost('entity_service/upload_file', '', formdata).subscribe(
      (data: any) => {
        if (data) {
          const result = this.encryptionService.decrypt(data.output);
          const parsedData = JSON.parse(result);
          this.extractedData = parsedData;
          this.isLoading = false;
          clearInterval(this.intervalId);
          
          this.toastr.success('Success !', 'Data uploaded successfully.');
        }
      },
      err => {
        this.isLoading = false;
        clearInterval(this.intervalId);
        
        this.toastr.error('Error data upload Failed.');
      }
    );
  }

  onGenerate() {
    this.testCases = [];
    this.testScenarios = [];
    this.flatTestCases = [];
    this.texts = ["Reviewing data", "Generating test cases", "Please wait"];
    this.isLoading = true;
    this.index = 0;
    this.startTextAnimation();

    let formData = new FormData();
  
    if (this.fileInput.nativeElement.files.length > 0) {
      Array.from(this.fileInput.nativeElement.files).forEach((file: File) => {
        formData.append('test_files', file);
      });
    }

    formData.append('industry_id', this.industry);
    formData.append('requirement_description', this.encryptionService.encrypt(this.requirementDescription));
    formData.append('verification_criteria', this.encryptionService.encrypt(this.verificationCriteria));

    this.apiService.apiRequestPost('ai_services/functional_test_case', '', formData).subscribe(
      (data: any) => {
        if (data) {
          const result = this.encryptionService.decrypt(data.output);
          const parsedResult = JSON.parse(result);
          
          if (parsedResult.error) {
            
            this.toastr.error(parsedResult.error, 'Error');
            this.testCases = null;
          } else {
            this.processData(parsedResult);
          }
          this.isLoading = false;
          clearInterval(this.intervalId);
          this.cdr.detectChanges();
        }
      },
      err => {
        this.isLoading = false;
      }
    );
  }

  onClear() {
    this.extractedData = '';
    this.requirementDescription = '';
    this.verificationCriteria = '';
    this.testCases = [];
    this.testScenarios = [];
    this.flatTestCases = [];
    this.fileNamesDisplay = [];
    this.fileInput.nativeElement.value = '';
  }

  startTextAnimation() {
    this.changeText();
    this.intervalId = setInterval(() => {
      this.changeText();
    }, 8000);
  }

  changeText() {
    if (this.index < this.texts.length) {
      this.currentText = this.texts[this.index];
      this.index++;
    } else {
      this.index = 0;
      this.currentText = this.texts[this.index];
    }
  }

  copyText() {
    let textToCopy = '';
  
    // Copying scenarios
    if (this.testScenarios && this.testScenarios.length > 0) {
      textToCopy += 'Test Scenarios:\n';
      this.testScenarios.forEach(scenario => {
        textToCopy += `- ${scenario.Scenarios}\n`;
      });
      textToCopy += '\n';
    }
  
    // Copying test cases
    if (this.testCases && this.testCases.length > 0) {
      this.testCases.forEach(testCase => {
        textToCopy += `Test Case: ${testCase['Test Case Name']}\n`;
        textToCopy += `Objective: ${testCase.Objective}\n`;
        textToCopy += `Precondition: ${testCase.Precondition}\n`;
        textToCopy += `Actions: ${testCase.Action.join(', ')}\n`;
        textToCopy += `Postcondition: ${testCase.Postcondition}\n`;
        textToCopy += `Pass/Fail Criteria: ${testCase['Pass/Fail Criteria']}\n\n`;
      });
    } else {
      console.error('No content found to copy.');
      return;
    }
  
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    
    this.toastr.success('Successfully copied content.');
  }
  

  splitString(str: string): string[] {
    return str.split('\n').filter(s => s.trim() !== '');
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      this.fileNamesDisplay.push(...Array.from(input.files).map(file => file.name));
    }
  }

  onTable() {
    this.isTable = !this.isTable;
  }

  processData(data) {
    this.testCases = data['Test Cases'];
    this.testScenarios = data['Test Scenarios'];
    this.flattenTestCases(data['Test Cases']);
  }

  flattenTestCases(testCases) {
    this.flatTestCases = testCases.map(testCase => ({
      'Test Case Name': testCase['Test Case Name'],
      Objective: testCase.Objective,
      Precondition: testCase.Precondition,
      Action: testCase.Action,
      Postcondition: testCase.Postcondition,
      'Pass/Fail Criteria': testCase['Pass/Fail Criteria']
    }));
  }

  removeFile(index: number): void {
    this.fileNamesDisplay.splice(index, 1);

    const dt = new DataTransfer();
    const inputEl: HTMLInputElement = this.fileInput.nativeElement;
    const { files } = inputEl;

    Array.from(files).forEach((file, i) => {
      if (i !== index) {
        dt.items.add(file);
      }
    });

    inputEl.files = dt.files;
  }

  exportToCsv() {
    const csvData = this.flatTestCases.map(testCase => ({
      'Test Case Name': testCase['Test Case Name'],
      Objective: testCase.Objective,
      Precondition: testCase.Precondition,
      Actions: testCase.Action.join('; '),
      Postcondition: testCase.Postcondition,
      'Pass/Fail Criteria': testCase['Pass/Fail Criteria']
    }));

    const csvContent = this.convertToCSV(csvData);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'functional_test_cases.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  convertToCSV(objArray: any[]): string {
    const header = Object.keys(objArray[0]).join(',');
    const rows = objArray.map(row => {
      return Object.values(row).map(value => {
        return `"${value}"`;
      }).join(',');
    });
    return [header].concat(rows).join('\n');
  }
}

