<div class="container login-cont mx-0">
    <div class="row">
        <div class="col-12 img-class">
            <img height="50" src="assets/images/Teratics_logo.png" alt="Logo">
        </div>
    </div>
    <div class="row" class="auth-container">
        <div class="col-12 login-card">
            <div class="login-form login-grp">
                <mat-card>
                    <span class="l-header">Forgot Password?</span>
                    <form [formGroup]="resetPwdFORM">
                        <div class="login-group">
                            <div fxLayout="column" class="pt-3">
                                <div fxLayout="row" fxLayout.xs="column">
                                    <mat-label class="h-t">Email</mat-label>
                                </div>
                                <div fxLayout="row" fxLayout.xs="column">
                                    <mat-form-field appearance="outline" class="example-full-width">
                                        <input #mobileno matInput id="emailId" name="emailId" required
                                            formControlName="sEmail" placeholder="Enter Your Registered Email"
                                            autocomplete="off" />
                                        <button
                                            [disabled]="resetPwdFORM.controls['sEmail'].value == '' || !resetPwdFORM.controls['sEmail'].valid"
                                            mat-icon-button matSuffix class="sufix-btn" fxLayout.xs="row"
                                            (click)="sendOTP()">
                                            <span [class]="getColor()">{{otpButton}}</span>
                                        </button>
                                    </mat-form-field>
                                </div>
                                <mat-error *ngIf="displayMessage.sEmail">
                                    {{displayMessage.sEmail}}
                                </mat-error>
                            </div>
                        </div>

                        <div class="login-group">
                            <div fxLayout.xs="column" fxLayout="column" fxLayout.xs="column" class="ip-field"
                                *ngIf="showOTP">
                                <div fxLayout.xs="column" fxLayout="row" fxLayoutGap="25px" fxLayoutGap.xs="0px">
                                    <mat-label class="label-font asterisk h-t" for="sConsentCode">Enter OTP
                                    </mat-label>
                                </div>
                                <div fxLayout.xs="column" fxLayout="row">
                                    <mat-form-field appearance="outline" class="example-full-width">
                                        <input matInput id="sConsentCode" formControlName="sConsentCode"
                                            name="sConsentCode" required placeholder="Enter Received OTP"
                                            autocomplete="off" />
                                        <button
                                            [disabled]="resetPwdFORM.controls['sConsentCode'].value == '' || !resetPwdFORM.controls['sConsentCode'].valid"
                                            mat-icon-button matSuffix class="sufix-btn" fxLayout.xs="row"
                                            (click)="verifyOTP()">
                                            verifyOTP
                                        </button>
                                    </mat-form-field>
                                </div>
                                <mat-error *ngIf="displayMessage.sConsentCode">
                                    {{displayMessage.sConsentCode}}
                                </mat-error>
                            </div>
                        </div>


                        <div fxLayout.xs="column" fxLayout="column" fxLayout.xs="column" class="ip-field"
                            *ngIf="passwordField">
                            <div fxLayout.xs="column" fxLayout="row" fxLayoutGap="25px" fxLayoutGap.xs="0px">
                                <mat-label class="label-font asterisk h-t" for="sNewPwd">New Password
                                </mat-label>
                            </div>
                            <div fxLayout.xs="column" fxLayout="row">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <input matInput id="sNewPwd" formControlName="sNewPwd" name="sNewPwd" required
                                        placeholder="Enter New Password" autocomplete="off" />
                                </mat-form-field>
                            </div>
                            <mat-error *ngIf="displayMessage.sNewPwd">
                                {{displayMessage.sNewPwd}}
                            </mat-error>
                        </div>
                    </form>


                    <div class="login-group">
                        <div fxLayout="row" class="p-1 mt-3" fxLayoutAlign="center center">
                            <div class="mb-2" fxLayoutAlign="center center">
                                <button mat-raised-button [disabled]="!resetPwdFORM.valid"
                                    class="red-button spinner-wrapper" (click)="resetPassword()" color="primary">
                                    Reset Password
                                </button>
                            </div>
                        </div>

                        <div fxLayout="row" *ngIf="this.resetPwdFORM.controls['sEmail'].value==''" class="p-1 pt-2"
                            fxLayoutAlign="center center">
                            <span class="c-r">Remember it? <a class="nv h-t" routerLink="/">Signin</a> </span>
                        </div>
                        <div fxLayout="row" *ngIf="showOTP" class="p-1 pt-2" fxLayoutAlign="center center">
                            <span class="c-r h-t"> Didn't receive the verification OTP? <a class="nv h-t"
                                    (click)="sendOTP()"> Resend again</a> </span>
                        </div>

                    </div>

                    <div *ngIf="successMsg" fxLayout="row wrap" class="p-1 pt-2" fxLayoutAlign="center center">
                        <div fxLayout="row" fxflex="100" class="s-c">Password Updated Successfully!! </div>
                        <div fxLayout="row" fxflex="100"> Please click &nbsp; <a class="nv h-t" routerLink="/"> here
                            </a> &nbsp; to Sign-in again. </div>
                    </div>

                </mat-card>
            </div>
        </div>
    </div>
</div>