import { ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren, Output } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/services/api/api.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/core/services/user/user.service';
import { EncryptService } from 'src/app/core/services/encrypt-decrypt/encrypt.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UploadComponent } from '../../shared/upload/upload.component';
import { IUploadConfig } from 'src/app/core/utils/interface/shared.interface';
@Component({
  selector: 'app-generate-requirements',
  templateUrl: './generate-requirements.component.html',
  styleUrls: ['./generate-requirements.component.scss']
})
export class GenerateRequirementsComponent implements OnInit {
  form: FormGroup;
  userStory: any = [{ id: 1, name: 'Brief Requirements', value: 'Brief' }, { id: 2, name: 'Detailed Requirements', value: 'Detailed' }, { id: 3, name: 'System Diagram Requirements', value: 'System Diagram' }]
  historyNav: boolean = false;
  isLoading: boolean = false;
  data: any = [];
  currentText: string;
  intervalId: any;
  index: number = 0;
  texts: string[] = [];
  topics: any[] = [];
  historyDetails: any = null;
  industry: any;
  activeType: 'Brief' | 'Detailed' | 'System Diagram';
  groupedTopics = [];
  console = console;
  fileUploaded = false;
  uploadedFileName: string = '';
  uploadedFileOutput: any;
  messageHide: boolean = false;
  userTypedText: string = '';
  initialUserTypedText: string = '';
  isHistoryClicked: boolean = false;
  isBriefDisabled: boolean = false;
  isDiagramDisabled: boolean = false;
  isBriefOrDetailedSelected: boolean = false;
  generateClicked: boolean = false;
  isEnglish = true;
  previousIsEnglish: boolean;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private uService: UserService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private _fb: FormBuilder,
    private encryptionService: EncryptService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.texts = ["Please wait", "Requirement generation in progress"];
    this.createForm();
    this.loadTopics();
    this.uService.industry$.subscribe(data => {
      this.industry = data;
    });

    // Update initialUserTypedText only if no file has been uploaded yet
    this.form.get('reqInput').valueChanges.subscribe(value => {
      if (!this.fileUploaded) {
        this.initialUserTypedText = value;
      }
    });
  }

  toggleLanguage() {
    this.isEnglish = !this.isEnglish;
  }

  groupTopicsByDate() {
    const groups = new Map();
    this.topics.forEach(topic => {
      const date = new Date(topic.timestamp).toLocaleDateString();
      const group = groups.get(date) || [];
      group.push(topic);
      groups.set(date, group);
    });
    this.groupedTopics = Array.from(groups, ([date, topics]) => ({ date, topics }));
  }

  isSideWindowOpen: boolean = false;

  toggleSideWindow(): void {
    this.isSideWindowOpen = !this.isSideWindowOpen;
  }

  setActiveType(type: 'Brief' | 'Detailed' | 'System Diagram') {
    this.activeType = type;
  }

  openPopup() {
    const uploadConfig: IUploadConfig = {
      accept: '.xgml',
      isMultiple: false
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = 'max-content'
    dialogConfig.panelClass = 'info-dialog'
    dialogConfig.data = { uploadConfig }
    this.dialog.open(UploadComponent, dialogConfig).afterClosed().subscribe(res => {
      if (res) {
        this.onGettext(res);
      }
    })

  }

  onGettext(formdata) {
    this.texts = ["File transfer underway", "Extracting data", "Please wait"];
    this.isLoading = true;
    this.index = 0;
    this.startTextAnimation();
    this.apiService.apiRequestPost('entity_service/upload_file', '', formdata).subscribe((data: any) => {
      if (data) {
        this.isLoading = false;
        clearInterval(this.intervalId);
        this.toastr.success('Success !', 'Data uploaded successfully.');
        this.fileUploaded = true;
        this.uploadedFileName = formdata.get('file').name;
        const result = this.encryptionService.decrypt(data.output);
        const parsedData = JSON.parse(result);
        this.uploadedFileOutput = parsedData;
        // Combine initial user-typed text with the new uploaded file text
        const combinedText = this.initialUserTypedText + (this.initialUserTypedText && this.uploadedFileOutput ? '\n' : '') + this.uploadedFileOutput;

        // Set the combined text in the textarea
        this.form.get('reqInput').setValue(combinedText);
      }
    },
      err => {
        this.isLoading = false;
        clearInterval(this.intervalId);
        // 
        // this.toastr.error('Error data upload Failed.');
      }
    );
  }


  createForm() {
    this.form = this._fb.group({
      reqInput: ['', Validators.required],
      includeVerify: [false, Validators.required,],
      userStory: ['Brief', Validators.required],
    })
  }

  copyText() {
    let textToCopy = '';

    if (this.form.get('userStory').value !== 'System Diagram') {
      // For Brief and Detailed requirements
      this.data.forEach(item => {
        textToCopy += `${item.name}\n${item.value}\n\n`;
      });
    } else {
      // For System Diagram requirements
      this.data.forEach(functionalReq => {
        textToCopy += `${functionalReq.name}\n${functionalReq.description}\n`;
        functionalReq.technical_requirements.forEach(techReq => {
          textToCopy += `  - ${techReq.name}\n    ${techReq.description}\n    Allocation: ${techReq.allocation}\n`;
        });
        textToCopy += '\n';
      });
    }

    textToCopy = textToCopy.trim();
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    this.toastr.success('Successfully Requirement Copied.');
  }

  getRawValue() {
    let item = this.form.getRawValue();
    const set = {
      question: this.encryptionService.encrypt(item.reqInput),
      requirement_format: item.userStory,
      verification_criteria: item.includeVerify,
      industry_id: this.industry
    }
    return set;
  }



  onGenerate() {
    let item = this.form.getRawValue();
    let questionText = item.reqInput;
    let fileName = this.fileUploaded ? this.uploadedFileName : '';
    if (this.fileUploaded) {
      questionText += '\n' + this.uploadedFileOutput;
    }
    let encryptedQuestion = this.encryptionService.encrypt(questionText);
    let language = this.isEnglish ? 'English' : 'German'; 
    let set = {
      question: encryptedQuestion,
      requirement_format: item.userStory,
      verification_criteria: item.includeVerify,
      industry_id: this.industry,
      file_name: fileName,
      language: language
    };
    if (item.userStory === 'Brief' || item.userStory === 'Detailed') {
      this.generateClicked = true; 
    }
    this.isLoading = true;
    this.index = 0;
    this.data = [];
    this.startTextAnimation();
    this.apiService.apiRequestPost('ai_services/generate_requirement', null, set).subscribe((data: any) => {
      if (data) {
        const result = this.encryptionService.decrypt(data.output);
        const parsedData = JSON.parse(result);
        this.data = parsedData;
        this.isLoading = false;
        this.messageHide = true;
        clearInterval(this.intervalId);
        this.loadTopics();
        // this.toastr.success('Success !', 'Successfully Requirement Generated.');
      }
    },
      err => {
        this.isLoading = false;
        clearInterval(this.intervalId);
        this.toastr.error('We are unable to process your request. Please try later');
      }
    );
  }

  loadTopics() {
    const queryParam = '?requirement_type=Generate';
    this.apiService.apiRequestGet('entity_service/topics', queryParam).subscribe(data => {
      if (data) { 
        const result = this.encryptionService.decrypt(data.output);
        const parsedData = JSON.parse(result);
        this.topics = parsedData?.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
        this.groupTopicsByDate();
      } else {
        this.topics = []; 
      }
    });
  }
  


  onHistory() {
    this.historyNav = !this.historyNav
  }


  onLoadHistory(topicId: number) {
    this.previousIsEnglish = this.isEnglish;
    this.texts = ["Please wait"];
    this.isHistoryClicked = true;
    this.isLoading = true;
    const queryParam = `?topic_id=${topicId}`;
    this.apiService.apiRequestGet('entity_service/history', queryParam).subscribe((data: any) => {
      if (data ) {
        this.isLoading = false;
        const result = this.encryptionService.decrypt(data.output);
        const parsedData = JSON.parse(result);
        const questionData = parsedData.question;
        this.data = parsedData.ai_output;
        this.form.reset();
        this.form.controls['reqInput'].setValue(questionData.question);
        this.form.controls['userStory'].setValue(questionData.requirement_format);
        this.form.controls['includeVerify'].setValue(questionData.verification_criteria);
        this.toggleSideWindow();
        this.cdr.detectChanges();
        this.isDiagramDisabled = questionData.requirement_format === 'Brief' || questionData.requirement_format === 'Detailed';
        this.fileUploaded = questionData.file_name ? true : false;
        this.uploadedFileName = questionData.file_name ? questionData.file_name : '';
        this.isEnglish = questionData.language === 'English';
      }
    },
    err => {
        this.isLoading = false;
        this.toastr.error('We are unable to process your request. Please try later');
        // this.isEnglish = this.previousIsEnglish;
    });
  }  

  setBriefOrDetailedSelected() {
    const userStory = this.form.get('userStory').value;
    this.isBriefOrDetailedSelected = (userStory === 'Brief' || userStory === 'Detailed') && this.isHistoryClicked;
    this.isDiagramDisabled = userStory === 'Brief' || userStory === 'Detailed';
  }

  onClear() {
    this.data = [];
    this.form.reset();
    this.createForm();
    this.fileUploaded = false;
    this.uploadedFileName = '';
    this.uploadedFileOutput = '';
    this.messageHide = false;
    this.isBriefDisabled = false;
    this.isDiagramDisabled = false;
    this.isHistoryClicked =false;
  }


  startTextAnimation() {
    this.changeText();
    this.intervalId = setInterval(() => {
      this.changeText();
    }, 8000);
  }


  changeText() {
    if (this.index < this.texts.length) {
      this.currentText = this.texts[this.index];
      this.index++;
    } else {
      this.index = 0;
      this.currentText = this.texts[this.index];
    }
  }

  exportReqIFFormat() {
    let requirements;
    let isSystemDiagram;
    let filename = 'exported_requirements.reqif';
    let language = this.isEnglish ? 'English' : 'German'; 
    if (this.form.get('userStory').value === 'Brief' || this.form.get('userStory').value === 'Detailed') {
      requirements = this.data.map(item => {
        return {
          name: item.name,
          value: item.value
        };
      });
      isSystemDiagram = false;
    } else if (this.form.get('userStory').value === 'System Diagram') {
      requirements = this.data;
      isSystemDiagram = true;
    }

    const payload = {
      requirement: requirements,
      filename: filename,
      is_system_diagram: isSystemDiagram,
      language: language
    };

    this.apiService.downloadFile('entity_service/export_reqif', payload, filename).subscribe(() => {
      this.toastr.success('Success!', 'Requirements exported successfully.');
    }, err => {
      this.toastr.error('Error', 'Failed to export requirements.');
    });
  }

}
