<div class="container-fluid d-flex flex-column vh-100">
    <div class="row flex-fill">
      <div class="col-12">
        <div class="d-flex flex-column h-100">
          <div class="mb-2 mt-3" style="flex: 0 0 20%;">
            <div class="card h-100">
              <div class="card-body" style="padding-left: 25px;">
                <div class="card-body" style="padding-left: 25px;">
                    <!-- First row with 3 columns -->
                    <div class="row">
                      <div class="col-4">
                        <!-- First column content -->
                        <label for="toppingsSelect" class="outside-mat-label">Reference Files</label>
                        <mat-form-field appearance="outline" class="custom-mat-form-field">
                          <mat-select [formControl]="toppings" multiple id="Referece_Files_Dropdown" placeholder="Click here to select">
                            <mat-select-trigger>
                              {{ toppings.value ? 'Click here to select' : 'Click here to select' }}
                            </mat-select-trigger>
                            <mat-option *ngFor="let topping of toppingList" [value]="topping" [id]="topping" >{{topping}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      
                      <div class="col-4">
                        <!-- Second column content -->
                        <label for="fileInput" class="outside-mat-label">New Requirement Files</label>
                        <mat-form-field style="cursor: pointer;" appearance="outline" class="custom-mat-form-field example-full-width" (click)="fileInput.click()">
                            <input matInput placeholder="Click here to upload" readonly>
                            <button matSuffix mat-icon-button class="upload-btn" color="primary" id="New_Requirement_File_Upload_button">
                                <img src="assets/images/new-icons/upload.svg" alt="Upload" class="upload-img">
                            </button>
                            <input type="file" #fileInput style="display: none" (change)="onFileSelected($event)" accept=".csv,.xls,.xlsx">
                        </mat-form-field>
                    </div>                           
                      
                      <div class="col-4" style="display: flex;justify-content: center;align-items: center;">
                        <!-- Third column content -->
                        <button mat-raised-button class="generate-btn" [disabled]="selectedToppingsDisplay.length == 0 || fileNamesDisplay.length == 0 "  id="Compare_Requirements_Compare_Button"(click)='onCompare()'>
                          <span class="gen-text">Compare</span>
                        </button>
                        <button mat-raised-button class="clear-btn" id="Compare_Requirements_Clear_Button" (click)="onClear()" [disabled]="selectedToppingsDisplay.length == 0 && fileNamesDisplay.length == 0 ">
                          <span class="clear-text">Clear</span>
                        </button>
                      </div>
                    </div>
                    
                    <!-- Second row with 3 columns -->
                    <div class="row">
                        <div class="col-4 selected-toppings" [class.visible]="selectedToppingsDisplay.length > 0">
                            <span class="truncated-text" [matTooltip]="selectedToppingsDisplay">{{ selectedToppingsDisplay }}</span>
                          </div>
                          <div class="col-4 selected-toppings" [class.visible]="selectedFilesVisible">
                            <span *ngFor="let name of fileNamesDisplay; let i = index" class="file-name"
                                  [matTooltip]="name"
                                  [ngStyle]="{'max-width': fileNamesDisplay.length === 1 ? 'none' : '180px'}">{{ name }}</span>
                          </div>
                        <div class="col-4">
                        </div>
                    </div>
                  </div>                  
              </div>

  
            </div>
          </div>
          <div class="col-12 mb-3" class="second-card">
            <div class="card h-100">
              <div *ngIf="requirements.length == 0" class="card-body d-flex align-items-center justify-content-center" >
                <div>
                    <div class="row h-85">
                        <div class="empty text-center" id="Generate_Requirement_Center_Text">
                            <img class="empty-img" src="assets/images/new-icons/empty.svg" />
                            <span class="empty-description"> Evaluate new requirements to quickly find duplicate or similar<br>
                                requirements from previously evaluated requirements</span>
                        </div>
                    </div>            
                </div>
                        
              </div >
                <div *ngIf="requirements.length > 0" class=" comparison-result-container comparison-result-header sticky-top" id="Compare_Requirements_Comparison_Result">
                  <h2 class="head-table">Comparison Result</h2>
                </div>

                <div class="table-container" *ngIf="requirements.length > 0">
                  <table  id="comparisonTable">
                    <thead>
                      <tr>
                        <th>File</th>
                        <th>Req_Id</th>
                        <th>Req_Text</th>
                        <th>AI_Match_Score</th>
                        <th>Ref_File</th>
                        <th>Ref_Req_Id</th>
                        <th>Ref_Req_Text</th>
                        <th>AI_Remarks</th>
                        <th>AI_Diff_Req_text</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let r of requirements; let i = index" [ngStyle]="{'background-color': getRowColor(r.AI_Match_Score)}">
                        <td>{{ r.Req_File }}</td>
                        <td>{{ r.Req_Id }}</td>
                        <td [innerHTML]="r.Req_Text"></td>
                        <td>{{ r.AI_Match_Score ? r.AI_Match_Score : '0' }}%</td>
                        <td>{{ r.AI_Match_Score > 0 ? r.Ref_File : '' }}</td>
                        <td>{{ r.AI_Match_Score > 0 ? r.Ref_Id : '' }}</td>
                        <td>{{ r.AI_Match_Score > 0 ? r.Ref_Text : '' }}</td>
                        <td [innerHTML]="r.AI_Match_Score > 0 ? r.AI_Remarks : ''"></td>
                        <td [innerHTML]="r.AI_Match_Score > 0 ? highlightWords(r.AI_Diff_Req_text) : ''"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="output-footer download-report-container download-report-footer sticky-bottom"
                  *ngIf="requirements.length > 0">
                  <button mat-raised-button class="change-btn" id="Compare_Requirements_Export_To_CSV_Button" (click)="exportToCsv()">
                    <span class="change-text">Export to CSV</span>
                  </button>
                  <button mat-raised-button class="change-btn" id="Compare_Requirements_Export_To_PDF_Button" (click)="exportToPdf()">
                    <span class="change-text">Export to PDF</span>
                  </button>
                </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isLoading" class="loader-container">
    <div class="ring-loader">
    </div>
    <div class="loading-text">{{ currentText }}</div>
  </div>
  