
<div class="container-fluid mt-4">
    <div class="row mb-4">
        <div class="col">
            <h2>User List</h2>
        </div>
        <div class="col text-end">
    
            <button mat-raised-button class="new-user-btn" 
            (click)="openUserForm()">
            <span class="user-text"> Add User</span>
        </button>

        </div>
    </div>

    <!-- Search and Filter Section -->
    <div class="row mb-3">
        <div class="col-md-3">
            <input type="text" class="form-control" placeholder="Search Users..." [(ngModel)]="searchUsers">
        </div>
    </div>

    <!-- Table Section -->
    <div class="table-responsive">
        <table class="table table-striped table-hover border">
            <thead class="table-light">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">USER NAME</th>
                    <th scope="col">MAIL</th>
                    <th scope="col">FEATURE ACCESS</th>
                    <th scope="col">PROMPT CONFIG</th>
                    <th scope="col">CREATED AT</th>
                    <th scope="col">ACTIVE</th>
                    <th scope="col">ACTIONS</th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let user of filteredUsers();; let i = index">
                    <td>{{ user.user_id }}</td>
                    <td>
                        {{user.username}}
                    </td>
                    <td>{{user.email}}</td>
                    <td>

                        <span *ngFor="let data of user.applications">
                           {{data.application_name}}<br>
                        </span>
                    </td>
                  
                    <td>
                        {{user.prompt_config}}<br>
                    </td> 

                    <td>
                        {{user.created_at}}<br>
                    </td> 

                    <td>
                        {{user.is_active}}<br>
                    </td>
                 
                    <td>
                        <mat-icon (click)="edituser(user)">edit</mat-icon>
                        <mat-icon (click)="openDialog(user.user_id)">delete_forever</mat-icon>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>

<ng-template #ConfirmationModal>
    <div class="p-2">
        <h1 mat-dialog-title>Confirmation</h1>
        <div mat-dialog-content>Are you sure want to delete this user?</div>
        <div mat-dialog-actions class="w-100 d-flex justify-content-end">
            <button mat-raised-button mat-dialog-close>Cancel</button>
            <button color="primary" mat-raised-button mat-dialog-close="true">Ok</button>
        </div>
    </div>
</ng-template>