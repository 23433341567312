import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ChartComponent } from 'ng-apexcharts';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/services/api/api.service';
import { EncryptService } from 'src/app/core/services/encrypt-decrypt/encrypt.service';
import { UserService } from 'src/app/core/services/user/user.service';



@Component({
  selector: 'app-teratest-review-requirement',
  templateUrl: './teratest-review-requirement.component.html',
  styleUrls: ['./teratest-review-requirement.component.scss']
})
export class TeratestReviewRequirementComponent {
  @ViewChild('wordContent', { static: false }) wordContent: ElementRef;
  editingProposal: boolean = false;
  editableProposal: string = '';
  selectedValue: string = 'Testability Review';
  isGraph: boolean = true;
  extractedData: string = '';
  dataSource: any = null;
  originalDataSource: any;
  currentText: string;
  intervalId: any;
  index: number = 0;
  texts: string[] = [];
  isLoading: boolean = false;
  isCompare: boolean = false;
  industry: any;
  isJiraStory: boolean = false;
  hasModifications: boolean = false;
  verificationCriteria: string = "";
  editingRequirement = false;
  editingVerification = false;
  editableRequirementProposal: string;
  editableVerificationProposal: any;
  constructor(private dialog: MatDialog, private apiService: ApiService, private toastr: ToastrService, private renderer: Renderer2,
    private cdr: ChangeDetectorRef, private uService: UserService, private encryptionService: EncryptService) {

  }

  ngOnInit(): void {
    this.uService.industry$.subscribe(data => {
      this.industry = data;
    });
  }

  onValueChange(event: any) {
    this.selectedValue = event.value;
  }

  toggleEdit(section: string) {
    if (section === 'requirement') {
      this.editingRequirement = true;
      this.editableRequirementProposal = this.dataSource.requirementInfo.info2_value;
    } else if (section === 'verification') {
      this.editingVerification = true;
      this.editableVerificationProposal = this.getContent();
    }
  }

  saveEdit(section: string) {
    if (section === 'requirement') {
      this.dataSource.requirementInfo.info2_value = this.editableRequirementProposal;
      this.editingRequirement = false;
    } else if (section === 'verification') {
        const lines = this.editableVerificationProposal.split('\n').map(line => line.trim());
        let preconditions = '';
        let constraints = '';
        let success = '';

        for (let line of lines) {
            if (line.startsWith('Preconditions and special conditions:')) {
                preconditions = line.replace('Preconditions and special conditions:', '').trim();
            } else if (line.startsWith('Constraints:')) {
                constraints = line.replace('Constraints:', '').trim();
            } else if (line.startsWith('Success criteria:')) {
                success = line.replace('Success criteria:', '').trim();
            }
        }

        this.dataSource.verificationInfo.info2_value = {
            Preconditions: preconditions,
            Constraints: constraints,
            Success: success
        };
      this.editingVerification = false;
        this.renderer.setProperty(
            this.wordContent.nativeElement,
            'innerText',
            `Preconditions: ${preconditions}\nConstraints: ${constraints}\nSuccess: ${success}`
        );}
    }

  getContent(): string {
    const contentString = this.wordContent.nativeElement.innerText;
    return contentString;
  }

  cancelEdit(section: string) {
    if (section === 'requirement') {
      this.editingRequirement = false;
    } else if (section === 'verification') {
      this.editingVerification = false;
    }
  }
  
  
  onReview() {
    this.texts = ["Reviewing data", "Collecting Information", "Please wait"];
    this.dataSource = null;
    this.isLoading = true;
    this.index = 0;
    this.startTextAnimation();
  
    const set = {
      'industry_id': this.industry,
      'requirement': this.encryptionService.encrypt(this.extractedData),
      'verification_criteria': this.encryptionService.encrypt(this.verificationCriteria),
    };
  
    this.apiService.apiRequestPost('/teratest/testablity_review', null, set).subscribe(
      (data: any) => {
        console.log('API Response (before decryption):', data);
  

        if (data && data.output) {
          try {
            console.log('Raw Output Data:', data.output);
  
            // Attempt to decrypt the data
            const decryptedData = this.encryptionService.decrypt(data.output);
            console.log('Decrypted Data:', decryptedData);
  
            const parsedData = JSON.parse(decryptedData);
  
            // Process Requirement section
            const requirementInfo = {
              info_key: parsedData?.Requirement?.info_key,
              info_value: parsedData?.Requirement?.info_value,
              info2: parsedData?.Requirement?.info2,
              info2_value: parsedData?.Requirement?.info2_value
            };
  
            // Process VerificationCriteria section
            const verificationInfo = {
              info_key: parsedData?.VerificationCriteria?.info_key,
              info_value: parsedData?.VerificationCriteria?.info_value,
              info2: parsedData?.VerificationCriteria?.info2,
              info2_value: parsedData?.VerificationCriteria?.info2_value
            };
  
            // Combine both sections into dataSource
            this.dataSource = {
              requirementInfo: requirementInfo,
              verificationInfo: verificationInfo
            };
  
          } catch (error) {
            console.error('Decryption or Parsing Error:', error);
            this.toastr.error('Failed to decrypt or parse the data. Please check the console for details.');
          }
        } else {
          this.toastr.error('No data received from the server.');
        }
  
        this.isLoading = false;
        clearInterval(this.intervalId);
        this.cdr.detectChanges();
      },
      err => {
        this.isLoading = false;
        clearInterval(this.intervalId);
        this.toastr.error('Error: Data upload failed.');
      }
    );
  }
  
  

  onClear() {
    this.extractedData = '';
    this.verificationCriteria = '';
    this.dataSource = null;
    this.isJiraStory = false;
    this.hasModifications = false;
  }



  startTextAnimation() {
    this.changeText();
    this.intervalId = setInterval(() => {
      this.changeText();
    }, 8000);
  }


  changeText() {
    if (this.index < this.texts.length) {
      this.currentText = this.texts[this.index];
      this.index++;
    } else {
      this.index = 0;
      this.currentText = this.texts[this.index];
    }
  }

  copyText() {
    let contentToCopy = '';
  
    // Check if dataSource is available
    if (this.dataSource) {
  
      // Assemble the content from Requirement Info
      if (this.dataSource.requirementInfo) {
        contentToCopy += `${this.dataSource.requirementInfo.info_key}:\n`;
        this.dataSource.requirementInfo.info_value.forEach(item => {
          contentToCopy += `\n${item.key}:\nAmbiguity: ${item.value.Ambiguity}\nSuggestion: ${item.value.Suggestion}\n`;
        });
        if (this.dataSource.requirementInfo.info2_value) {
          contentToCopy += `\n${this.dataSource.requirementInfo.info2}:\n${this.editingRequirement ? this.editableRequirementProposal : this.dataSource.requirementInfo.info2_value}\n`;
        }
      }
  
      // Assemble the content from Verification Info
      if (this.dataSource.verificationInfo) {
        contentToCopy += `\n${this.dataSource.verificationInfo.info_key}:\n`;
        this.dataSource.verificationInfo.info_value.forEach(item => {
          contentToCopy += `\n${item.key}:\nAmbiguity: ${item.value.Ambiguity}\nSuggestion: ${item.value.Suggestion}\n`;
        });
        if (this.dataSource.verificationInfo.info2_value) {
          contentToCopy += `\n${this.dataSource.verificationInfo.info2}:\n${this.editingVerification ? this.editableVerificationProposal : this.dataSource.verificationInfo.info2_value}\n`;
        }
      }
    }
  
    // Use the Clipboard API to copy the structured text
    navigator.clipboard.writeText(contentToCopy).then(() => {
      this.toastr.success('Text content copied successfully.');
    }).catch(() => {
      this.toastr.error('Failed to copy text content.');
    });
  }
  
  copySpecificText(section: string) {
    let contentToCopy = '';
    let message = '';  
  
    if (section === 'requirement' && this.dataSource.requirementInfo && this.dataSource.requirementInfo.info2_value) {
      contentToCopy = this.dataSource.requirementInfo.info2_value;
      message = 'Refined requirement copied successfully'
    } else if (section === 'verification' && this.dataSource.verificationInfo && this.dataSource.verificationInfo.info2_value) {
      contentToCopy = this.dataSource.verificationInfo.info2_value;
      message = 'Refined verification criteria copied successfully'
    }
  
    if (contentToCopy) {
      navigator.clipboard.writeText(contentToCopy).then(() => {
        this.toastr.success(message);
        message = '';
      }).catch(() => {
        this.toastr.error('Failed to copy text content.');
      });
    } else {
      this.toastr.error('No content available to copy.');
    }
  }
  

}
