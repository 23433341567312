import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/services/api/api.service';
import { EncryptService } from 'src/app/core/services/encrypt-decrypt/encrypt.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { IUploadConfig } from 'src/app/core/utils/interface/shared.interface';
import { UploadComponent } from '../../shared/upload/upload.component';

@Component({
  selector: 'app-fmea',
  templateUrl: './fmea.component.html',
  styleUrls: ['./fmea.component.scss']
})
export class FMEAComponent {
  extractedData: string = '';
  currentText: string;
  intervalId: any;
  index: number = 0;
  texts: string[] = [];
  isLoading: boolean = false;
  dataSource: any = [] ;
  industry: any;
  constructor(private dialog: MatDialog, private apiService: ApiService, private toastr: ToastrService,
    private cdr: ChangeDetectorRef, private uService: UserService, private encryptionService:EncryptService) {
  }


  
  ngOnInit(): void {
    this.uService.industry$.subscribe(data => {
      this.industry = data;
    });
  }


  openPopup() {
    const uploadConfig: IUploadConfig = {
      accept: '.txt,.pdf,.docs,.docx',
      isMultiple : false
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = 'max-content'
    dialogConfig.panelClass = 'info-dialog'
    dialogConfig.data = {uploadConfig}
    this.dialog.open(UploadComponent, dialogConfig).afterClosed().subscribe(res => {
      if (res) {
        this.onGettext(res);
      }
    })
  }

  onGettext(formdata) {
    this.texts = ["File transfer underway", "Extracting data", "Please wait"];
    this.isLoading = true;
    this.index = 0;
    this.startTextAnimation();
    this.apiService.apiRequestPost('entity_service/upload_file', '', formdata).subscribe((data: any) => {
      if (data) {
        const result = this.encryptionService.decrypt(data.output);
        const parsedData = JSON.parse(result);
        this.extractedData = parsedData;
        this.isLoading = false;
        clearInterval(this.intervalId);
        
        this.toastr.success('Success !', 'Data uploaded successfully.');

      }
    },
      err => {
        this.isLoading = false;
        clearInterval(this.intervalId);
        // this.toastr.error('Error data upload Failed.');
      }
    );

  }




  onGenerate() { 
    this.dataSource = [];
    this.texts = ["Reviewing data", "Collecting Information", "Please wait"];
    this.isLoading = true;
    this.index = 0;
    this.startTextAnimation();
    let set = {
        'industry_id': this.industry,
        'text': this.encryptionService.encrypt(this.extractedData)
    }
    this.apiService.apiRequestPost('ai_services/FMEA', null, set).subscribe(
        (data: any) => {
            if (data) {
                const result = this.encryptionService.decrypt(data.output);
                const parsedData = JSON.parse(result);
                this.dataSource = parsedData;
                this.isLoading = false;
                clearInterval(this.intervalId);
                this.cdr.detectChanges();
            }
        },
        err => {
            this.isLoading = false;
            clearInterval(this.intervalId);
            // this.toastr.error('Error data upload failed.');
        }
    );
  }




  onClear() {
    this.extractedData = '';
    this.dataSource = [];
  }


  startTextAnimation() {
    this.changeText();
    this.intervalId = setInterval(() => {
      this.changeText();
    }, 8000);
  }


  changeText() {
    if (this.index < this.texts.length) {
      this.currentText = this.texts[this.index];
      this.index++;
    } else {
      this.index = 0;
      this.currentText = this.texts[this.index];
    }
  }


  exportToCsv(): void {
    let csvData = this.convertToCsv(this.dataSource);
    let blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    let link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'FMEA.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private convertToCsv(data: any[]): string {
    const replacer = (key: any, value: any) => (value === null ? '' : value);
    const header = ['component', 'function', 'failure_mode', 'effects', 'root_causes', 'mitigation_measures'];
  
    // Convert data to CSV format
    let csv = data.map((row: any) => {
      return header.map(fieldName => {
        if (Array.isArray(row[fieldName])) {
          // Concatenate array elements into a single string
          return `"${row[fieldName].join(', ')}"`;
        } else {
          // Handle other fields normally
          return JSON.stringify(row[fieldName], replacer);
        }
      }).join(',');
    });
  
    csv.unshift(header.join(','));
    return csv.join('\r\n');
  }
  
  

}
