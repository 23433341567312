import { ChangeDetectorRef, Component,OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/services/api/api.service';
import { EncryptService } from 'src/app/core/services/encrypt-decrypt/encrypt.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { IUploadConfig } from 'src/app/core/utils/interface/shared.interface';
import { UploadComponent } from '../../shared/upload/upload.component';

interface Textarea {
  value: string;
}

@Component({
  selector: 'app-hara-using-hazop',
  templateUrl: './hara-using-hazop.component.html',
  styleUrls: ['./hara-using-hazop.component.scss']
})
export class HaraUsingHazopComponent {
  extractedData: string = '';
  currentText: string;
  intervalId: any;
  index: number = 0;
  texts: string[] = [];
  isLoading: boolean = false;
  isScenarioSelected: boolean = false;
  dataSource: any = [] ;
  industry: any;
  selectedGuideWord: string;
  selectedGuideWords: string[] = [];
  operatingScenarios: Textarea[] = [{ value: '' }];
  operatingModes: Textarea[] = [{ value: '' }];
  combinedTextareas = [...this.operatingScenarios, ...this.operatingModes];

  constructor(private dialog: MatDialog, private apiService: ApiService, private toastr: ToastrService,
    private cdr: ChangeDetectorRef, private uService: UserService, private encryptionService:EncryptService) {
  }


  guideWords: any = [
    { id: 1, name: 'Incorrect', value: 'Incorrect' },
    { id: 2, name: 'No', value: 'No' },
  ]
  
  ngOnInit(): void {
    this.uService.industry$.subscribe(data => {
      this.industry = data;
    });
  }
 
  get isScenarioAndModesFilled(): boolean {
    return (
      this.extractedData.trim() !== '' &&
      this.selectedGuideWords.length > 0 &&
      this.combinedTextareas.length % 2 === 0 && 
      this.combinedTextareas.every((textarea, index) => {
        if (index % 2 === 0) {
          const scenario = textarea.value.trim();
          const mode = this.combinedTextareas[index + 1].value.trim();
          return scenario !== '' && mode !== '';
        }
        return true; 
      })
    );
  }

  isAnyFieldFilled(): boolean {
    return this.extractedData.trim() !== '' ||
           this.selectedGuideWords.length > 0 ||
           this.operatingScenarios.some(scenario => scenario.value.trim() !== '') ||
           this.operatingModes.some(mode => mode.value.trim() !== '');
  }
  
  addOperatingScenariosAndModes() {
    if (this.operatingScenarios.length > 0 && this.operatingModes.length > 0) {
      const scenarioValue = this.operatingScenarios[0].value;
      const modeValue = this.operatingModes[0].value;
      if (scenarioValue.trim() !== '' && modeValue.trim() !== '') {
        this.operatingScenarios.push({ value: '' }); 
        this.operatingModes.push({ value: '' }); 
        this.combinedTextareas.push({ value: '' }, { value: '' });
        this.isScenarioSelected = true;
      }
    }
  }

  removeOperatingScenario(index: number) {
    if (index >= 0 && index < this.combinedTextareas.length) {
      const arrayIndex = Math.floor(index / 2);
      this.operatingScenarios.splice(arrayIndex, 1);
      this.operatingModes.splice(arrayIndex, 1);
      // Remove both the scenario and mode from the combinedTextareas array
      this.combinedTextareas.splice(arrayIndex * 2, 2);
      // If the last scenario and mode were removed, add an empty pair
      if (this.operatingScenarios.length === 0 && this.operatingModes.length === 0) {
        this.operatingScenarios.push({ value: '' });
        this.operatingModes.push({ value: '' });
        this.combinedTextareas.push({ value: '' }, { value: '' });
      }
    }
  }
  
  openPopup() {
    const uploadConfig: IUploadConfig = {
      accept: '.txt,.pdf,.docs,.docx',
      isMultiple : false
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = 'max-content'
    dialogConfig.panelClass = 'info-dialog'
    dialogConfig.data = {uploadConfig}
    this.dialog.open(UploadComponent, dialogConfig).afterClosed().subscribe(res => {
      if (res) {
        this.onGettext(res);
      }
    })
  }

  onGettext(formdata) {
    this.texts = ["File transfer underway", "Extracting data", "Please wait"];
    this.isLoading = true;
    this.index = 0;
    this.startTextAnimation();
    this.apiService.apiRequestPost('entity_service/upload_file', '', formdata).subscribe((data: any) => {
      if (data) {
        const result = this.encryptionService.decrypt(data.output);
        const parsedData = JSON.parse(result);
        this.extractedData = parsedData;
        this.isLoading = false;
        clearInterval(this.intervalId);
        this.toastr.success('Success !', 'Data uploaded successfully.');

      }
    },
      err => {
        this.isLoading = false;
        clearInterval(this.intervalId);
        // this.toastr.error('Error data upload Failed.');
      }
    );

  }
  
  onGenerate() {
    this.texts = ["Reviewing data", "Collecting Information", "Please wait"];
    this.isLoading = true;
    this.index = 0;
    this.startTextAnimation();
  
    // Encrypting operating scenarios and modes
    const encryptedScenariosAndModes = this.combinedTextareas
      .map(textarea => this.encryptionService.encrypt(textarea.value.trim()))
      .filter(encryptedValue => encryptedValue !== '');
  
    // Separate encrypted scenarios and modes
    const encryptedOperatingScenarios = encryptedScenariosAndModes.filter((_, index) => index % 2 === 0);
    const encryptedOperatingModes = encryptedScenariosAndModes.filter((_, index) => index % 2 !== 0);
  
    // Joining encrypted scenarios and modes
    const operatingScenariosPayload = encryptedOperatingScenarios.join(',');
    const operatingModesPayload = encryptedOperatingModes.join(',');
  
    let set = {
      'industry_id': this.industry,
      'item_definition': this.encryptionService.encrypt(this.extractedData),
      'hazop_guidewords': this.encryptionService.encrypt(this.selectedGuideWords.join(', ')),
      'operating_scenarios': operatingScenariosPayload,
      'operating_mode': operatingModesPayload
    };
  
    this.apiService.apiRequestPost('ai_services/HARA', null, set).subscribe((data: any) => {
      if (data) {
        const result = this.encryptionService.decrypt(data.output);
        const parsedData = JSON.parse(result);
        this.dataSource = parsedData;
        console.log(this.dataSource);
        this.isLoading = false;
        clearInterval(this.intervalId);
        this.cdr.detectChanges();
      }
    },
    err => {
      this.isLoading = false;
      clearInterval(this.intervalId);
      // 
      // this.toastr.error('Error data upload failed.');
    });
  }

  onValueChange(event: any) {
    if (event.isUserInput) {
      this.selectedGuideWords = event.source.value;
    }
  }

  onClear() {
    this.extractedData = '';
    this.selectedGuideWords = [];
    this.dataSource = [];
    this.isScenarioSelected = false;
    this.operatingScenarios = [{ value: '' }];
    this.operatingModes = [{ value: '' }];
    this.combinedTextareas = [...this.operatingScenarios, ...this.operatingModes];
  }

  startTextAnimation() {
    this.changeText();
    this.intervalId = setInterval(() => {
      this.changeText();
    }, 8000);
  }

  changeText() {
    if (this.index < this.texts.length) {
      this.currentText = this.texts[this.index];
      this.index++;
    } else {
      this.index = 0;
      this.currentText = this.texts[this.index];
    }
  }


  exportToCsv(): void {
    let csvData = this.convertToCsv(this.dataSource); 
    let blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    let link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'Hara.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private convertToCsv(data: any[]): string {
    if (data.length === 0) return '';

    const replacer = (key: any, value: any) => {
        if (typeof value === 'string') {
            value = value.replace(/"/g, '""');
            if (value.includes(',') || value.includes('\n') || value.includes('"')) {
                value = `"${value}"`;
            }
        }
        return value === null ? '' : value;
    };
    const header = Object.keys(data[0]);

    let csv = data.map(row =>
        header.map(fieldName => replacer(fieldName, row[fieldName])).join(',')
    );
    csv.unshift(header.join(','));
    return csv.join('\r\n');
  }

}
