<div class="container px-4 my-4">
  <div class="head mb-4 d-flex justify-content-between align-items-center">
    <h2>{{ isUpdate ? "Update" : "Add" }} Users</h2>
    <mat-icon (click)="onClose()" class="cursor-pointer">close</mat-icon>
  </div>

  <div class="formset">
    <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
      <!-- Second Row -->
      <div class="row">
        <div class="row-md-3 mb-3">
          <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>User Name</mat-label>
            <input
              matInput
              formControlName="username"
              [ngClass]="{ 'is-invalid': submitted && f['username'].errors }"
            />
          </mat-form-field>
        </div>

        <div class="row-md-3 mb-3">
          <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Email</mat-label>
            <input
              matInput
              type="email"
              formControlName="email"
              [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
            />
          </mat-form-field>
        </div>

        <div class="row-md-3 mb-3 w-100">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Feature Access</mat-label>
            <mat-select formControlName="application_ids" multiple>
              <mat-option
                *ngFor="let data of mainFeatures"
                [value]="data.application_id"
                >{{ data.application }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div class="row-md-3 mb-3 w-100">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>User Type</mat-label>
            <mat-select formControlName="user_profile">
              <mat-option
                *ngFor="let data of userTypesList"
                [value]="data.value"
                >{{ data.label }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <div
            class="invalid-feedback"
            *ngIf="submitted && f['user_profile'].errors?.['required']"
          >
            Feature Access is required
          </div>
        </div>

        <!-- Checkboxes Row -->
        <div class="row mb-3">
          <div class="col-md-6">
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                formControlName="prompt_config"
                id="prompt_config"
              />
              <label class="form-check-label" for="prompt_config"
                >Promt Enabled</label
              >
            </div>
          </div>
        </div>

        <div class="row mb-3" *ngIf="isUpdate">
          <div class="col-md-6">
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                formControlName="is_active"
                id="is_active"
              />
              <label class="form-check-label" for="is_active">is Active</label>
            </div>
          </div>
        </div>
      </div>

      <!-- Form Buttons -->
      <div class="row mt-4">
        <div class="col-12">
          <button mat-raised-button [disabled]="userForm.invalid" class="new-user-btn mx-2">
            <span class="user-text"> {{ isUpdate ? "Update" : "Save" }}</span>
          </button>
          <button mat-raised-button class="new-user-btn" (click)="resetForm()">
            <span class="user-text">Reset </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
