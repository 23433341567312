<mat-toolbar class="header">
  <img src="assets/images/Teratics_logo.png" class="logo" alt="Innobbon" />
  <span class="example-spacer"></span>
  <div class="pt-4 px-2 drop" *ngIf="!isTeraTest">
    <form [formGroup]="form">
      <mat-form-field appearance="outline" class="example-full-width">
        <mat-label>Industry</mat-label>
        <mat-select formControlName="industry" id="industry_dropdown" name="industry_dropdown"
          (selectionChange)="onSelectIndustry($event)" [disabled]="!isDropdownEnabled">
          <mat-option *ngFor="let option of industryOptions" [value]="option.industry_id" [id]="option.industry">
            {{option.industry}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div> 

  <div class="pt-4 px-2 drop" *ngIf="isAdmin">
    <form [formGroup]="form">
      <mat-form-field appearance="outline" class="example-full-width">
        <mat-label>LLM</mat-label>
        <mat-select formControlName="LLM" id="llm_dropdown" name="llm_dropdown" (selectionChange)="onSelectLLM($event)" [disabled]="!isDropdownEnabled">
          <mat-option *ngFor="let option of LLMoptions" [value]="option.llm_id" [id]="option.llm">
            {{option.llm}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>

  <a class="home-icons" routerLink="/features/dashboard">
    <img src="assets/images\Vector.svg" alt="Home">
  </a>


  <a class="profile-user" id="profile_icon" [matMenuTriggerFor]="menu">
    <img src="assets/images/profile_user.svg" alt="profile">
    <span class="n-name">profile_icon</span>
  </a>


<button class="back-btn" mat-button  *ngIf="shouldShowBackButton" (click)="goBack()">
  <mat-icon>arrow_back</mat-icon> <span class="back-text"> Back </span>
</button>

  <mat-menu #menu="matMenu">
    <div class="p-name-container">
      <span class="p-name">{{userName}}</span>
    </div>
    <!-- <button mat-menu-item (click)="onToggle(true)">Theme</button> -->
    <button mat-menu-item (click)="onNavigate()" *ngIf="isAdmin">Mange Users</button>
    <button mat-menu-item id="signout_ID" (click)="logout()">Logout</button>
  </mat-menu>
</mat-toolbar>


<mat-drawer-container class="example-container" *ngIf="sidenav">
  <mat-drawer #drawer (closed)="onToggle(false)" position="end" mode="over" [opened]="sideNavBar">
    <div class="side-navbar">
      <div class="pt-2 head" fxLayout="row" fxLayoutAlign="end center">
        <mat-icon (click)="onToggle(false)" id="close_btn" class="cursor-p" matTooltip="Close">close</mat-icon>
      </div>
      <h2 class="mx-4">Themes</h2>

      <div class="nk-opt-list px-2">
        <div class="nk-opt-item" data-key="skin" data-update="bluelite" (click)="changeTheme('inno')">
          <span class="nk-opt-item-bg skin-bluelite"> </span>
          <span class="nk-opt-item-name">Blue</span>
        </div>

        <div class="nk-opt-item" data-key="skin" data-update="green" (click)="changeTheme('green')">
          <span class="nk-opt-item-bg skin-green"></span>
          <span class="nk-opt-item-name">Green</span>
        </div>

        <div class="nk-opt-item" data-key="skin" data-update="purple" (click)="changeTheme('purple')">
          <span class="nk-opt-item-bg skin-purple"></span>
          <span class="nk-opt-item-name">Purple</span>
        </div>

        <div class="nk-opt-item active" data-key="skin" data-update="blu" (click)="changeTheme('blu')">
          <span class="nk-opt-item-bg skin-blu"></span>
          <span class="nk-opt-item-name">blue shade</span>
        </div>
      </div>


    </div>
  </mat-drawer>
</mat-drawer-container>

<div *ngIf="isLoading" class="loader-container">
  <div class="ring-loader">
  </div>
</div>