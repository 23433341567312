import { ApplicationList, UserTypesList } from 'src/app/core/utils/data/shared-data';
import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/core/services/api/api.service';
import { IUserTypesList } from 'src/app/core/utils/interface/shared.interface';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent {


  userForm!: FormGroup;
  submitted = false;
  dataSource: any;
  isUpdate: boolean = false;
  mainFeatures : any;
  userTypesList: IUserTypesList[] = UserTypesList;

  constructor(
    private fb: FormBuilder, 
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) data: any,
    private _dialogRef: MatDialogRef<ManageUsersComponent>
  ) {
    this.createForm();
    this.dataSource = data.user;
    this.isUpdate = data.isUpdate;
    this.initializeForm();
  }

  ngOnInit() {
  this.loadFeatures();
  }

  private createForm() {
    this.userForm = this.fb.group({
      username: ['', Validators.required],
      application_ids: [[], Validators.required],
      email: ['', [Validators.required, Validators.email]],
      is_active: [true],
      prompt_config: [false],
      user_profile: ['USER', [Validators.required]]
    });
  }

  private initializeForm() {
    if (this.isUpdate) {
      this.patchFormValues();
    } else {
       this.createForm();
    }
  }

  private patchFormValues() {
    this.userForm.patchValue({
      username: this.dataSource.username,
      application_ids: this.dataSource.applications.map((app :any) => app.application_id),
      email: this.dataSource.email,
      is_active: this.dataSource.is_active,
      prompt_config : this.dataSource.prompt_config,
      user_profile: this.dataSource.user_profile
    });
  }



  loadFeatures() {
    this.apiService.apiRequestGet('entity_service/preferences').subscribe(data => {
       this.mainFeatures = data.application_list; 
      });
  }



  get f() {
    return this.userForm.controls;
  }


  onSubmit() {
    this.submitted = true;
    if(!this.isUpdate){
      if (this.userForm.valid) {
        this.apiService.apiRequestPost('auth/signup', null, this.userForm.value).subscribe((data)=>{
          this._dialogRef.close(true);
        })
        
      }
    }else if(this.userForm.valid){
      const url = `admin_service/edit_user?user_id=${this.dataSource.user_id}`
      this.apiService.apiRequestPut(url, null, this.userForm.value).subscribe((data)=>{
        this._dialogRef.close(true);
      })
    }
  
  }

  resetForm() {
    this.submitted = false;
    this.userForm.reset({
      active: true,
    });
  } 

 

  onClose() {
    this._dialogRef.close(false);
  }
}
