import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/services/api/api.service';
import { UploadComponent } from '../../shared/upload/upload.component';
import { UserService } from 'src/app/core/services/user/user.service';
import { EncryptService } from 'src/app/core/services/encrypt-decrypt/encrypt.service';
import { IUploadConfig } from 'src/app/core/utils/interface/shared.interface';

@Component({
  selector: 'app-group-requirements',
  templateUrl: './group-requirements.component.html',
  styleUrls: ['./group-requirements.component.scss']
})
export class GroupRequirementsComponent {
  groupBy: any = [
    { id: 'Group_By_SW_Module_Dropdown', name: 'SW Module', value: 'Software Module'  },
    { id: 'Group_By_Vehicle_Function_Dropdown', name: 'Vehicle Function', value: 'Vehicle Function' },
    // { id: 'Group_By_Vehicle_Domain_Dropdown', name: 'Vehicle Domain', value: 'Vehicle Domain'}
  ]
  extractedData: string = "";
  currentText: string;
  intervalId: any;
  index: number = 0;
  texts: string[] = [];
  isLoading: boolean = false;
  isCompare: boolean = false;
  isTable: boolean = false;
  softwareRequirements: any = null;
  industry: any;
  selectedValue: any = null;
  displayedColumns: string[] = ['domain', 'system', 'requirement'];
  constructor(private dialog: MatDialog, private apiService: ApiService, private toastr: ToastrService, private uService: UserService,
    private cdr: ChangeDetectorRef, private encryptionService:EncryptService) {

  }

  ngOnInit(): void {
    this.uService.industry$.subscribe(data => {
      this.industry = data;
      this.selectedValue = null;
    });
  }

  onValueChange(data: any) {
    this.selectedValue = data.value;
  }

  onTabel() {
    this.isTable = !this.isTable;
  }

  onNodes() {
    this.toastr.info('Feature under development');
  }

  openPopup() {
    const uploadConfig: IUploadConfig = {
      accept: '.txt,.pdf,.docs,.docx',
      isMultiple : false
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = 'max-content'
    dialogConfig.panelClass = 'info-dialog'
    dialogConfig.data = {uploadConfig}
    this.dialog.open(UploadComponent, dialogConfig).afterClosed().subscribe(res => {
      if (res) {
        this.onGettext(res);
      }
    })
  }

  onGettext(formdata) {
    this.texts = ["File transfer underway", "Extracting data", "Please wait"];
    this.isLoading = true;
    this.index = 0;
    this.startTextAnimation();
    this.apiService.apiRequestPost('entity_service/upload_file', '', formdata).subscribe((data: any) => {
      if (data) {
        const result = this.encryptionService.decrypt(data.output);
        const parsedData = JSON.parse(result);
        this.extractedData = parsedData;
        this.isLoading = false;
        clearInterval(this.intervalId);
        this.toastr.success('Success !', 'Data uploaded successfully.');

      }
    },
      err => {
        this.isLoading = false;
        clearInterval(this.intervalId);
        // this.toastr.error('Error data upload Failed.');
      }
    );

  }

  ongroupby() {
    this.softwareRequirements = null;
    this.texts = ["Reviewing data", "Collecting Information", "Please wait"];
    this.isLoading = true;
    this.index = 0;
    this.startTextAnimation();
    let set = {
        'industry_id': this.industry,
        'text': this.encryptionService.encrypt(this.extractedData),
        'grouping_choice': this.selectedValue
    }

    this.apiService.apiRequestPost('ai_services/group_requirement', null, set).subscribe(
        (data: any) => {
            if (data) {
                this.isTable = data.is_table;
                const result = this.encryptionService.decrypt(data.output);
                const parsedData = JSON.parse(result);
                this.softwareRequirements = parsedData;
                this.isLoading = false;
                clearInterval(this.intervalId);
                this.cdr.detectChanges();
            }
        },
        err => {
            this.isLoading = false;
            clearInterval(this.intervalId);
            // 
            // this.toastr.error('Error data upload Failed.');
        }
    );
  }


  onClear() {
    this.extractedData = '';
    this.softwareRequirements = null;
  }


  startTextAnimation() {
    this.changeText();
    this.intervalId = setInterval(() => {
      this.changeText();
    }, 8000);
  }


  changeText() {
    if (this.index < this.texts.length) {
      this.currentText = this.texts[this.index];
      this.index++;
    } else {
      this.index = 0;
      this.currentText = this.texts[this.index];
    }
  }


  copyText() {
    let textToCopy = '';
    this.softwareRequirements.forEach(requirement => {
      textToCopy += `${requirement.Group_name}\n`;
      requirement.requirements.forEach(req => {
        textToCopy += ` - ${req.value}\n`;
      });
      textToCopy += '\n';
    });
    if (textToCopy.trim() === '') {
      console.error('No content found to copy.');
      return;
    }
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    this.toastr.success('Successfully copied content.');
  } 

  exportToCsv() {
    const csvContent = this.convertArrayToCSV(this.softwareRequirements);
    this.downloadFile(csvContent, 'data.csv', 'text/csv');
  }

  exportToExcel() {
    const excelContent = this.convertArrayToExcel(this.softwareRequirements);
    this.downloadFile(excelContent, 'data.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
  }

  private convertArrayToCSV(data: any[]): string {
    const csvRows = [];

    // Header
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));

    // Data
    for (const item of data) {
      const values = headers.map(header => item[header]);
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  }


  private convertArrayToExcel(data: any[]): any {
    return 'excel_content';
  }

  private downloadFile(content: any, fileName: string, contentType: string) {
    const blob = new Blob([content], { type: contentType });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

}


