import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/services/api/api.service';
import { EncryptService } from 'src/app/core/services/encrypt-decrypt/encrypt.service';
import { UserService } from 'src/app/core/services/user/user.service';

interface Textarea {
  value: string;
}
@Component({
  selector: 'app-cybersecurity-tara',
  templateUrl: './cybersecurity-tara.component.html',
  styleUrls: ['./cybersecurity-tara.component.scss']
})


export class CybersecurityTaraComponent {
  extractedAssestData: string = '';
  extractedAttackData:string = '';
  currentText: string;
  intervalId: any;
  index: number = 0;
  texts: string[] = [];
  isLoading: boolean = false;
  dataSource: any = [] ;
  industry: any;
  textareas: Textarea[] = [{ value: '' }];
  attackDetails: string[] = [];
  
  constructor(private dialog: MatDialog, private apiService: ApiService, private toastr: ToastrService,
    private cdr: ChangeDetectorRef, 
    private uService: UserService, 
    private encryptionService:EncryptService,
    ) {
  }

  ngOnInit(): void {
    this.uService.industry$.subscribe(data => {
      this.industry = data;
    });
  }
 
  addTextarea() {
    this.textareas.push({ value: '' });
    
  }
  
  allTextareasFilled(): boolean {
    return this.textareas.every(textarea => textarea.value.trim() !== '');
  }

  isAnyFieldFilled(): boolean {
    return this.extractedAssestData.trim() !== '' || this.textareas.some(textarea => textarea.value.trim() !== '');
  }


  removeTextarea(index: number) {
    this.textareas.splice(index, 1);
  }

  onGenerate() {
    this.dataSource = [];
    this.texts = ["Reviewing data", "Collecting Information", "Please wait"];
    this.isLoading = true;
    this.index = 0;
    this.startTextAnimation();
    this.attackDetails = this.textareas.map(textarea => textarea.value);
    let set = {
      'industry_id': this.industry,
      'asset':  this.encryptionService.encrypt(this.extractedAssestData),
      'attack': this.encryptionService.encrypt(JSON.stringify(this.attackDetails))
    }

    this.apiService.apiRequestPost('ai_services/TARA', null, set).subscribe((data: any) => {
      if (data) {
        const result = this.encryptionService.decrypt(data.output);
        const parsedData = JSON.parse(result);
        this.dataSource = parsedData;
        this.isLoading = false;
        clearInterval(this.intervalId);
        this.cdr.detectChanges();
      }
    },
      err => {
        this.isLoading = false;
        clearInterval(this.intervalId);
        // this.toastr.error('Error data upload failed.');
      });
  }

  onClear() {
    this.extractedAssestData = '';
    this.textareas  = [{ value: '' }];
    this.attackDetails = [];
    this.dataSource = [];
  }

  startTextAnimation() {
    this.changeText();
    this.intervalId = setInterval(() => {
      this.changeText();
    }, 8000);
  }

  changeText() {
    if (this.index < this.texts.length) {
      this.currentText = this.texts[this.index];
      this.index++;
    } else {
      this.index = 0;
      this.currentText = this.texts[this.index];
    }
  }

  exportToCsv(): void {
    let csvData = this.convertToCsv(this.dataSource);
    let blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    let link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'Cybersecurity-TARA.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private convertToCsv(data: any[]): string {
    if (!data.length) return '';
    const replacer = (key: any, value: any) => (value === null ? '' : value);
    const header = Object.keys(data[0]).map(field => field === 'S_NO' ? 'S.No' : field);
    let csv = data.map((row: any) =>
      header
        .map(fieldName => JSON.stringify(row[fieldName.replace('S.No', 'S_NO')], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    return csv.join('\r\n');
  }

}
