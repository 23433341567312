import { CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from './module/shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule, DatePipe } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { MaterialModule } from './material.module';
import { AccountModule } from './module/account/account.module';
import { FeaturesModule } from './module/features/features.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AuthGuard } from './core/services/guards/auth.guard';
import { InterceptorService } from './core/services/interceptor/interceptor.service';
import { HeaderComponent } from './layout/header/header.component';
import { LayoutComponent } from './layout/layout/layout.component';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MarkdownModule } from 'ngx-markdown';
import { environment } from 'src/environment/environment';
import { SECRET_KEY } from 'src/environment/environment.factory';
import { AdministrationModule } from './module/administration/administration.module';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LayoutComponent
  ],


  imports: [
    AppRoutingModule,
    // BrowserModule,
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    FlexLayoutModule,
    FeaturesModule,
    LayoutModule,
    AccountModule,
    SharedModule,
    MaterialModule,
    AdministrationModule,
    MarkdownModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'custom-toast',
      preventDuplicates: true,
      progressBar: true,
      tapToDismiss:true,
      progressAnimation: 'increasing',
      autoDismiss: true  
    }),

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-IN' },
    {provide: LOCALE_ID, useValue: 'en-IN' },
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'INR'},
    { provide: SECRET_KEY, useValue: environment.sKey },
    DatePipe,AuthGuard
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
