import { AdministrationModule } from './module/administration/administration.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/services/guards/auth.guard';
import { LayoutComponent } from './layout/layout/layout.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./module/account/account.module').then(m => m.AccountModule)},

  {
    path: 'features',
    component: LayoutComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () => import('./module/features/features.module').then(m => m.FeaturesModule)
      },
      {
        path: 'admin',
        canActivate:[AuthGuard],
        loadChildren:()=> import('./module/administration/administration.module').then(m=>m.AdministrationModule)
      }
    ]
  },
  { path: '**', redirectTo: '/auth/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
