<div class="main">
    <div *ngIf="industryIsEmpty" class="row warning-message-row ">
      <div class="col-6 alert alert-warning" role="alert">
        <span class="pt-3 warning-message">An industry selection is required to move forward and use the feature.</span>
      </div>
    </div>
  
    <section class="dashboard-section">
      <div class="container mx-auto">
        <div class="card" *ngFor="let feature of featuresList" [routerLink]="industryIsEmpty ? '' : feature.route"
          [id]="feature.feature_id">
          <div class="card-body">
            <div class="icon-container">
              <img [lazyLoad]="feature.logo" alt="icons" class="img-fluid">
            </div>
            <div class="text-container">
              <div class="card-title">{{feature.feature_name}}</div>
              <div class="card-text">{{feature.description}}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div> 

  <div *ngIf="isLoading" class="loader-container">
    <div class="ring-loader"></div>
  </div>