import { IApplicationList, IUserTypesList } from "../interface/shared.interface";

const ApplicationList =  <IApplicationList[]>[
    {
      application: "ReqAI",
      application_id: 1,
      logo: "assets/images/reqai.svg",
      route: '../feature-dashboard'
    },
    {
      application: "TeraTestAI",
      application_id: 2,
      logo: "assets/images/testai.svg",
      route: '../feature-dashboard',
    },
    {
      application: "SecurityAI",
      application_id: 3,
      logo: "assets/images/securityAI.svg",
      route: '../feature-dashboard',
    },
    {
      application: "SafetyAI",
      application_id: 4,
      logo: "assets/images/safetyAI.svg",
      route: '../feature-dashboard',
    }
  ];

  const UserTypesList =  <IUserTypesList[]>[
    {
      id: 1,
      label: "Admin",
      value: "ADMIN"
    },
    {
      id: 2,
      label: "User",
      value: "USER"
    }
  ]


  export { ApplicationList, UserTypesList };