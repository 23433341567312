import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ManageUsersComponent } from '../manage-users/manage-users.component';
import { ApiService } from 'src/app/core/services/api/api.service';
interface Users {
  user_id: number;
  username: string;
  email: string;
  prompt_config : boolean;
  created_at: string;
  applications: [ 
    {application_id : number,
    application_name : string}
  ];
  is_active: boolean;
}
@Component({
  selector: 'app-users-overview',
  templateUrl: './users-overview.component.html',
  styleUrls: ['./users-overview.component.scss']
})


export class UsersOverviewComponent {

  Users: Users[] = [];
  searchUsers: string = '';
  @ViewChild('ConfirmationModal', { static: true }) ConfirmationModal: TemplateRef<any>;

  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
  ) {}

  ngOnInit() {
    this.getUsers();
  }


 
  getUsers(): void {

    this.apiService.apiRequestGet('admin_service/users_list').subscribe(data => {
  
    this.Users  = data;
      
    });


  }

  filteredUsers() {
    const searchTerm = this.searchUsers.toLowerCase().trim();
    
    if (!searchTerm) {
      return this.Users;
    }

    return this.Users.filter(user => {
      const username = (user.username || '').toLowerCase();
      
      return username.includes(searchTerm) 
    });
  }

  openUserForm() {
    const dialogRef = this.dialog.open(ManageUsersComponent, {
      width: '30%',
      maxWidth: '1200px',
      data: { isUpdate: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getUsers();
      }
    });
  }

  edituser(user: any) { 
    const dialogRef = this.dialog.open(ManageUsersComponent, {
      width: '30%',
      maxWidth: '1200px',
      data: { user, isUpdate: true }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getUsers();
      }
    });
  }





  openDialog(id: number) {
    this.dialog.open(this.ConfirmationModal).afterClosed().subscribe((value) => {
      if (value) {
        const url = `admin_service/delete_user?user_id=${id}`;
        this.apiService.apiRequestPatch(url, null, null).subscribe((data) => {
          this.getUsers();
        });
      }
    });
  }


}
