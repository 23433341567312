import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/services/api/api.service';
import { UploadComponent } from '../../shared/upload/upload.component';
import { UserService } from 'src/app/core/services/user/user.service';
import { EncryptService } from 'src/app/core/services/encrypt-decrypt/encrypt.service';
import { IUploadConfig } from 'src/app/core/utils/interface/shared.interface';
export interface SoftwareRequirement {
  category: string;
  requirement: { value: any }[];
  criticality?: string;
}
@Component({
  selector: 'app-classify-requirements',
  templateUrl: './classify-requirements.component.html',
  styleUrls: ['./classify-requirements.component.scss']
})
export class ClassifyRequirementsComponent {
  extractedData: string = '';
  currentText: string;
  intervalId: any;
  index: number = 0;
  texts: string[] = [];
  isLoading: boolean = false;
  isCompare: boolean = false;
  isTable: boolean = false;
  softwareRequirements: any = null;
  categories: any
  industry: any;
  displayedColumns: string[] = ['requirement', 'category', 'criticality'];
  categoryColorsMap: Map<string, { bg: string, text: string }>;
  constructor(private dialog: MatDialog, private apiService: ApiService, private toastr: ToastrService,
    private cdr: ChangeDetectorRef, private uService: UserService, private encryptionService:EncryptService) {
  }

  ngOnInit(): void {
    this.uService.industry$.subscribe(data => {
      this.industry = data;
    });
  }

  onTabel() {
    this.isTable = !this.isTable;
  }


  onNodes() {
    
    this.toastr.info('Feature under development', 'Coming soon!.');
  }

  openPopup() {
    const uploadConfig: IUploadConfig = {
      accept: '.txt,.pdf,.docs,.docx',
      isMultiple : false
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = 'max-content'
    dialogConfig.panelClass = 'info-dialog'
    dialogConfig.data = {uploadConfig}
    this.dialog.open(UploadComponent, dialogConfig).afterClosed().subscribe(res => {
      if (res) {
        this.onGettext(res);
      }
    })
  }

  onGettext(formdata) {
    this.texts = ["File transfer underway", "Extracting data", "Please wait"];
    this.isLoading = true;
    this.index = 0;
    this.startTextAnimation();
    this.apiService.apiRequestPost('entity_service/upload_file', '', formdata).subscribe((data: any) => {
      if (data) {
        const result = this.encryptionService.decrypt(data.output);
        const parsedData = JSON.parse(result);
        this.extractedData = parsedData;
        this.isLoading = false;
        clearInterval(this.intervalId);
        
        this.toastr.success('Success !', 'Data uploaded successfully.');

      }
    },
      err => {
        this.isLoading = false;
        clearInterval(this.intervalId);
        // 
        // this.toastr.error('Error data upload Failed.');
      }
    );

  }


  getRequirementsByCategory(category: string) {
    return this.softwareRequirements.filter(req => req.category === category);
  }

  onClassify() {
    this.softwareRequirements = null;
    this.texts = ["Reviewing data", "Collecting Information", "Please wait"];
    this.isLoading = true;
    this.index = 0;
    this.startTextAnimation();
    let set = {
      'industry_id': this.industry,
      'text':  this.encryptionService.encrypt(this.extractedData)
    }
    this.apiService.apiRequestPost('ai_services/classify_requirement', null, set).subscribe((data: any) => {
      if (data) {
        const result = this.encryptionService.decrypt(data.output);
        const parsedData = JSON.parse(result);
        this.softwareRequirements = parsedData;
        const categoryColorsMap = this.createCategoryColorsMap();

        this.softwareRequirements.forEach((x, index) => {
          const categoryName = x.category.toLowerCase();
          if (categoryColorsMap.has(categoryName)) {
            x['cssclass'] = categoryColorsMap.get(categoryName);
          }
        });
        this.categories = [...new Set(this.softwareRequirements.map(x => x.category))];
        this.isLoading = false;
        clearInterval(this.intervalId);
        this.cdr.detectChanges();
      }
    },
      err => {
        this.isLoading = false;
        clearInterval(this.intervalId);
        // this.toastr.error('Error data upload failed.');
      });
  }

  createCategoryColorsMap() {
    return new Map([
      ['software requirements', 'orange'],
      ['calibration requirements', 'blue'],
      ['non-functional requirements', 'green'],
      ['design decision', 'yellow'],
      ['safety requirement','pink']
    ]);
  }

  getDotColor(criticality: string): string {
    switch (criticality.toLowerCase()) {
      case 'high':
        return 'red'; 
      case 'medium':
        return 'orange'; 
      case 'low':
        return 'green'; 
      default:
        return 'grey'; 
    }
  }

  onClear() {
    this.extractedData = '';
    this.softwareRequirements = null;
  }


  startTextAnimation() {
    this.changeText();
    this.intervalId = setInterval(() => {
      this.changeText();
    }, 8000);
  }


  changeText() {
    if (this.index < this.texts.length) {
      this.currentText = this.texts[this.index];
      this.index++;
    } else {
      this.index = 0;
      this.currentText = this.texts[this.index];
    }
  }


  copyText() {
    let textToCopy = '';

    this.categories.forEach(category => {
      textToCopy += `${category}\n`;
      const requirements = this.getRequirementsByCategory(category);
      requirements.forEach(requirement => {
        textToCopy += `- ${requirement.requirement}\n`;
      });
      textToCopy += '\n';
    });

    if (textToCopy.trim() === '') {
      console.error('No content found to copy.');
      return;
    }

    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    this.toastr.success('Successfully copied content.');
  }

}
