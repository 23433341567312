
export enum HTTPSTATUSCODE {
    Success = 200,
    Created = 201,
    NoResult = 204,
    UnAuthorized = 401,
    ValidationError = 422,
    BadRequest = 400,
    InternalServerError = 500,
    UnSupported = 415,
    ExpectationFailed = 417,
    NotFound = 404,
}

export enum HTTPSTATUS {
    Success = "Success !",
    Failure = "Error !",
    Nodata = "NO DATA FOUND"
}

export enum PAGEACCESS {
    Read =  1,
    Write = 2,
    NoAccess = 0
}

export enum PAGEVIEW {
    CREATE = 1,
    EDIT = 2,
    READ = 3
}

export enum ACTION {
    CREATE =  "CREATE",
    EDIT = "EDIT",
    VIEW = "VIEW",
    COPY = "COPY"
}

export enum USERPROFILE {
    ADMIN = 'ADMIN',
    USER = 'USER',
    INTERNAL = 'INTERNAL',
}

export enum DASHBOARDURL{
    REQAI = '../features/reqai-dashboard',
    TERATEST = '../features/teratestai-dashboard',
    INTERNAL = '../features/dashboard',
}